import React from 'react';
import Toast from '../Toast/Toast';
import mailIcon from '../../images/social-networks/mail.svg';
import linkedinIcon from '../../images/social-networks/linkedin.svg';
import youtubeIcon from '../../images/social-networks/youtube.svg';

const S4N_EMAIL = 'info@s4n.co';

const CompanyInformation: React.FC = () => {
  const [isToastOpen, setIsToastOpen] = React.useState(false);

  function copyS4NEmailInClipBoard() {
    navigator.clipboard.writeText(S4N_EMAIL).then(() => {
      setIsToastOpen(true);
    });
  }

  return (
    <>
      <Toast
        isOpen={isToastOpen}
        autoHideDuration={3000}
        onExit={() => setIsToastOpen(false)}
        position={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <p className="s-font-sz-16 s-px-2 s-py-2 text-nowrap">Email copied to clipboard</p>
      </Toast>
      <div className="main-footer__company-information">
        <div className="main-footer__social_networks s-mb-13">
          <button
            type="button"
            className="main-footer__email m-mr-13 font-f-cambria s-font-sz-14 m-font-sz-18"
            onClick={copyS4NEmailInClipBoard}
          >
            <img src={mailIcon} alt="Email" className="s-mr-3" />
            {S4N_EMAIL}
          </button>
          <a
            href="https://www.linkedin.com/company/s4n/"
            target="_blank"
            rel="noreferrer"
            className="s-mr-10"
          >
            <img src={linkedinIcon} alt="Linkedin" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCTjo9hCqLFm0DdSiHRQjKhQ"
            target="_blank"
            rel="noreferrer"
          >
            <img src={youtubeIcon} alt="Youtube" />
          </a>
        </div>
        <p className="main-footer__copyright text-center s-font-sz-14 m-font-sz-18 font-f-cambria">
          © Copyright
          {' '}
          {new Date().getFullYear()}
          {' '}
          S4N
        </p>
      </div>
    </>
  );
};

export default CompanyInformation;
