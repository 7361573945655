import React from 'react';

function useMinWidth(width: number): boolean {
  const [isValid, setIsValid] = React.useState(false);

  function handleMediaQueryChange(mediaQueryEvent: MediaQueryListEvent) {
    setIsValid(mediaQueryEvent.matches);
  }

  React.useEffect(() => {
    const minMediaQuery = window.matchMedia(`(min-width: ${width}px)`);

    setIsValid(minMediaQuery.matches);
    minMediaQuery.addEventListener('change', handleMediaQueryChange);

    return () => minMediaQuery.removeEventListener('change', handleMediaQueryChange);
  }, []);

  return isValid;
}

export default useMinWidth;
