import React from 'react';
import { Link } from 'gatsby';
import Menu from './Menu';

const Header: React.FC = () => (
  <>
    <div className="header-big-block s-mt-6 m-mt-20" />
    <header className="main-header s-px-6 m-px-12 s-mb-8 m-mb-23">
      <Link to="/">
        <h1 className="font-w-xbold s-font-sz-25 m-font-sz-63">
          <span className="text-primary">S4N</span>
          <span className="text-grey">DECODED</span>
        </h1>
      </Link>
      <a
        className="main-header__link text-primary font-w-medium m-font-sz-41"
        href="https://www.s4n.co/"
        target="_blank"
        rel="noreferrer"
      >
        S4N.CO
      </a>
    </header>
    <Menu onMenuClick={() => {}} />
  </>
);

export default Header;
