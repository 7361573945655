import React from 'react';

const Arrow: React.FC = () => (
  <a
    href="https://jobs.lever.co/s4n"
    target="_blank"
    rel="noreferrer"
    className="main-header__arrow-container"
  >
    <strong className="s-font-sz-10 m-font-sz-14 font-w-semi-bold text-grey">
      Start working with us
    </strong>
    <figure className="main-header__arrow">
      <div className="main-header__arrow-stick" />
    </figure>
  </a>
);

export default Arrow;
