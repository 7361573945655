import React from 'react';
import Arrow from './Arrow';
import TwoLines from './TwoLines';

interface Props {
  onMenuClick: () => void;
}

const Menu: React.FC<Props> = () => (
  <nav className="main-header__navigation-menu s-pl-12 m-pl-22 s-pr-4 m-pr-8 l-pr-12 s-mb-8">
    <TwoLines />
    <Arrow />
  </nav>
);

export default Menu;
