import React from 'react';
import { Helmet } from 'react-helmet';
import Header from './Header/Header';
import '../styles/index.scss';
import Footer from './Footer/Footer';
import PageContext from '../contexts/PageContext';
// @ts-ignore
import favicon from '../images/favicon.ico';

interface MetaData {
  title?: string;
  image?: string;
  description?: string;
}

interface Props {
  header?: React.ReactNode;
  metaData?: MetaData;
}

const Layout: React.FC<Props> = ({ children, metaData, header = <Header /> }) => {
  const { currentPage } = React.useContext(PageContext);

  const title = metaData?.title || `S4N DECODED | ${currentPage.toUpperCase()}`;
  const description = metaData?.description || 'S4N - A team that builds complex software products';
  const image = metaData?.image
    || 'https://images.ctfassets.net/4y9keriamokn/2VDEvx8Yrvwi97jzbalqUR/d1fe7a3bd09a52d078cdcf769a0ae6e2/home-about-1.png';

  const meta = [
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:site_name',
      content: 'S4N Decoded',
    },
    {
      property: 'og:image',
      content: image,
    },
    {
      property: 'og:image:width',
      content: '817',
    },
    {
      property: 'og:image:height',
      content: '449',
    },
    {
      property: 'og:image:type',
      content: 'image/png',
    },
    {
      property: 'twitter:card',
      content: 'summary_large_image',
    },
    {
      property: 'twitter:title',
      content: title,
    },
    {
      property: 'twitter:site',
      content: '@s4n_co',
    },
    {
      property: 'twitter:description',
      content: description,
    },
    {
      property: 'og:type',
      content: 'website',
    },
  ];

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        title={title}
      >
        {/* Open graph meta information */}
        {meta.map((metaInfo) => (
          <meta key={metaInfo.property} property={metaInfo.property} content={metaInfo.content} />
        ))}
        <meta name="description" content={description} />
        <meta name="twitter:image" content={image} />
        <meta name="keywords" content="software,development,enterprise" />

        <meta charSet="utf-8" />
        <link rel="icon" href={favicon} />
        <link
          href="https://fonts.googleapis.com/css?family=Montserrat:400,500,600,800&display=swap"
          rel="stylesheet"
        />
        <link rel="preload" href="/fonts/fonts.css" as="styles" />
        <link
          rel="preload"
          href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.2/animate.min.css"
          as="styles"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.2/animate.min.css"
        />
      </Helmet>
      <div className="main-blog-container">
        {header}
        <main className="s-mb-18">{children}</main>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
