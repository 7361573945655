import React from 'react';

interface ToastPosition {
  vertical: 'top' | 'bottom';
  horizontal: 'left' | 'right' | 'center';
}

interface ToastProps {
  isOpen: boolean;
  className?: string;
  position?: ToastPosition;
  autoHideDuration?: number;
  onExit?: () => void;
}

const Toast: React.FC<ToastProps> = ({
  children,
  autoHideDuration,
  onExit,
  isOpen,
  position = { vertical: 'top', horizontal: 'right' },
  className,
}) => {
  const timerReference = React.useRef<number>();
  const toastReference = React.useRef<HTMLDivElement>(null);
  const [isHidden, setIsHidden] = React.useState(false);
  const positionClass = position ? `${position.horizontal}-${position.vertical}` : '';

  function handleOnCloseToast() {
    if (toastReference.current) {
      toastReference.current.classList.add('hidden');

      toastReference.current.addEventListener(
        'transitionend',
        () => {
          setIsHidden(true);
          if (onExit) {
            onExit();
          }
        },
        { once: true },
      );
    }
  }

  function handleTimerPause() {
    if (timerReference.current !== undefined) clearTimeout(timerReference.current);
  }

  function handleTimerResume() {
    if (!autoHideDuration) return;
    if (timerReference.current !== undefined) clearTimeout(timerReference.current);
    timerReference.current = window.setTimeout(handleOnCloseToast, autoHideDuration);
  }

  React.useEffect(() => {
    if (!autoHideDuration || !isOpen) return;
    if (timerReference.current !== undefined) clearTimeout(timerReference.current);

    timerReference.current = window.setTimeout(handleOnCloseToast, autoHideDuration);
  }, [autoHideDuration, isOpen]);

  React.useEffect(() => {
    if (isOpen) setIsHidden(false);
  }, [isOpen]);

  if (!isOpen || isHidden) {
    return null;
  }

  return (
    <div
      className={`decoded-toast ${positionClass} ${className || ''}`}
      ref={toastReference}
      onMouseEnter={handleTimerPause}
      onMouseLeave={handleTimerResume}
    >
      {children}
    </div>
  );
};

export default Toast;
