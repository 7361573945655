import React from 'react';
import Location from './Location';
import CompanyInformation from './CompanyInformation';

const Footer: React.FC = () => {
  const [activeIndex, setActiveIndex] = React.useState(-1);

  function handleOnClick(index: number) {
    if (index === activeIndex) {
      setActiveIndex(-1);
    } else {
      setActiveIndex(index);
    }
  }

  return (
    <footer className="main-footer m-px-8 m-pt-10 s-pb-15 m-pb-10 l-px-15 l-pt-12">
      <section className="main-footer__locations l-grid s-colum-1 m-column-2 m-c-gap-20 m-pb-12 s-mb-8">
        <Location
          city="BOGOTÁ, COL"
          address="Ak 45 # 103-40"
          addressDetails="Office 406"
          index={0}
          currentIndex={activeIndex}
          onChange={handleOnClick}
        />
        <Location
          city="SEATTLE, USA"
          address="107 Spring St."
          addressDetails="Seattle, WA 98104"
          phone="Tel: (650) 695 - 1 289"
          index={1}
          currentIndex={activeIndex}
          onChange={handleOnClick}
        />
      </section>
      <CompanyInformation />
    </footer>
  );
};

export default Footer;
