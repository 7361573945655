/* eslint-disable no-unused-vars */
import React from 'react';
import useMinWidth from '../../hooks/useMinWidth';

const TABLET_WIDTH = 768;

interface Props {
  city: string;
  address: string;
  addressDetails: string;
  phone?: string;
  index: number;
  currentIndex: number;
  onChange: (index: number) => void;
}

const Location: React.FC<Props> = ({
  city,
  address,
  addressDetails,
  phone,
  onChange,
  index,
  currentIndex,
}) => {
  const isAtLeastTablet = useMinWidth(TABLET_WIDTH);
  const isActive = index === currentIndex;

  function onChangeProxy(newIndex: number) {
    if (!isAtLeastTablet) {
      onChange(newIndex);
    }
  }

  function handleEnterKey(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.which === 13) {
      onChangeProxy(index);
    }
  }

  return (
    <div
      className="main-footer__location s-pt-5 s-pr-7 s-pb-3 s-pl-7"
      role="checkbox"
      aria-checked={isActive}
      tabIndex={0}
      onClick={() => onChangeProxy(index)}
      onKeyPress={handleEnterKey}
    >
      <address>
        <h2 className="font-f-montserrat s-font-sz-16 m-font-sz-24 l-font-sz-25 font-s-normal font-w-xbold">
          {city}
        </h2>
        <span className="s-font-sz-14 m-font-sz-22 font-f-cambria font-s-normal">{address}</span>
        {(isActive || isAtLeastTablet) && (
          <>
            <br />
            <span className="s-font-sz-14 m-font-sz-22 font-f-cambria font-s-normal">
              {addressDetails}
            </span>
            <br />
            {!!phone && <span className="s-font-sz-14 m-font-sz-22 font-f-cambria font-s-normal">{phone}</span>}
          </>
        )}
      </address>
      <img
        src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiPgogICAgPGRlZnM+CiAgICAgICAgPHBhdGggaWQ9ImEiIGQ9Ik0xNi41OSA4LjU5TDEyIDEzLjE3IDcuNDEgOC41OSA2IDEwbDYgNiA2LTZ6Ii8+CiAgICA8L2RlZnM+CiAgICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC02IC04KSI+CiAgICAgICAgPHBhdGggZD0iTTAgMGgyNHYyNEgweiIvPgogICAgICAgIDx1c2UgZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJub256ZXJvIiB4bGluazpocmVmPSIjYSIvPgogICAgPC9nPgo8L3N2Zz4K"
        alt="Arrow down"
        className={isActive ? 'main-footer__arrow-down active' : 'main-footer__arrow-down'}
      />
    </div>
  );
};

export default Location;
